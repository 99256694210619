<template>
  <div>커뮤니티 > 문제신고</div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  name: "CommunityReport",
  components: {},
  data() {
    return {};
  },
  created() {
    this.$Progress.start();
    console.log(client);
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  destroyed() {
    /**
     * destroyed
     */
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
